<template>
  <div class="case-conent">
    <div class="hd">成绩统计情况</div>
    <!--学历案日志列表-->
    <div class="table-content-search">
      <div class="search">
        <div class="fromItem">
          <el-select v-model="ruleForm.subjectId" @change="subjectChange" @clear="subjectClear()" clearable placeholder="学科选择">
            <el-option
                    v-for="item in SubjectList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="fromItem">
          <el-input placeholder="请输入图书名称" clearable class="input-with-select" style="width: 300px;" v-model="ruleForm.bookName" @change="nameChange()" @clear="nameClear()"></el-input>
        </div>
        <div class="fromItem">
          <el-button type="primary" size="small" @click="ObjBtn()"
                     style="text-align: left;"><i class="icon-daoru1-copy"></i> 搜索
          </el-button>
        </div>

      </div>
      <div class="table">
        <span v-if="code==200">
          <el-table
              border
              :header-cell-style="{background:'#D1EEFE',color:'#333'}"
              :data="listData"
          >
          <el-table-column prop="sort" label="序号"  header-align="center" width="80"></el-table-column>
             <el-table-column prop="bookName" label="图书名称" header-align="center"></el-table-column>
          <el-table-column prop="folderName" label="课时名称" header-align="center"></el-table-column>
          <el-table-column prop="paperName" label="练习名称" header-align="center"></el-table-column>

          <el-table-column prop="createTime" label="提交时间" header-align="center" width="200"></el-table-column>
          <el-table-column prop="score" label="成绩" header-align="center" width="80"></el-table-column>
        </el-table>
        </span>
        <span v-else>
              <div class="kong">
                <img src="../../assets/images/kong.png">
                <p>暂无查询结果！</p>
              </div>
        </span>
      </div>
      <div class="page">
        <div class="pag-box" v-if="code==200">
          <div class="total-box">
            <span class="total_pag">共<em> {{ total }} </em>条</span>
          </div>
          <el-pagination
              :current-page="currentPage"
              background
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              v-if="total != 0"
          >
            <span class="pag_sise">每页显示</span>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import {ParqueryScoreListObj} from '@/api/parent'
export default {
  name: '',
  data() {
    return {
      clickFlag:null,
      code:null,
      ruleForm:{
        subjectId:null,
        bookName:null,
      },
      listData:[],
      pageSize:null,
      pageCurrent:null,
      total:null,
    }
  },
  created() {
    this.EducationCase = this.$route.query.EducationCase;
    this.subjectObj()
    this.listObj()
  },
  watch: {
    // 监视搜索词变化
    $route(){
      this.clickFlag= this.$route.query.clickFlag
    },

  },
  methods: {
    subjectObj() {
      this.ruleForm.subjectId = this.SubjectList[0].id
    },
    //查询筛选的学科列表
    listObj() {
      let params = {
        subjectId: this.ruleForm.subjectId,//学科
        bookName: this.ruleForm.bookName,//图书，
        pageCurrent: this.currentPage,//初始页
        pageSize: this.pageSize,//每页的数据
      }
      ParqueryScoreListObj(params).then((res) => { // eslint-disable-line no-unused-vars
        if(res.code==200) {
          this.listData = res.data.records
          this.code = res.code;
          this.pageSize = res.data.size;
          this.currentPage = res.data.current;
          this.total = res.data.total
        } else if(res.code==209) {
          this.code = res.code;
        }
      })
    },
    ObjBtn() {
     this.listObj()
    },
    //充值订单列表分页
    handleSizeChange(val) {
      this.pagesize = val;
      this.listObj();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // this.setContextData("currentPage8", this.currentPage);
      // 刷新页面内容
      this.listObj();
    },
    // 学科
    subjectChange($event){
      this.ruleForm.subject = $event
      // this.listObj()
    },
    // 清除学科
    subjectClear() {
      this.ruleForm.subject = ''
      // this.listObj()
    },
    // 名称
    nameChange(){
      sessionStorage.setItem('parHomeaChievCompleteName',this.ruleForm.bookName)
    },
    // 清除状态
    statusClear() {
      this.ruleForm.bookName = ''
      //sessionStorage.setItem('bookSubjectStatus',this.bookSubject.status = '')
      sessionStorage.removeItem('parHomeaChievCompleteName');
      // this.getList()
    },


  },
  computed:{
    ...mapState([
      'SubjectList',
    ]),
  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
.case-conent {
  margin: 20px auto;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 0.9;

  .hd {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    background: #37AEFF;
    text-align: center;
    color: #fff;
    opacity: 1;
  }

  .table-content-search {
    width: 96%;
    margin: 10px auto;
    .search {
      width: 100%;
      height: 40px;
      display: flex;
      margin-bottom: 10px;
      justify-content: flex-end;
      .fromItem {
        margin-left: 10px;

        .el-button--small {
          padding: 10px 10px;
          font-size: 16px;
          background-color: #0055C5;
          border-color:#0055C5;
          color: #fff
        }

      }
    }
    .table {
      height: 60%;
    }
    .page {
      height: 40px;
    }
  }

  .pag-box {
    width: 100%;
    padding-top: 10px;
    box-sizing: content-box;
    justify-content: center;
    display: flex;

    .total-box {
      float: left;
      line-height: 32px;
      margin-right: 10px;
      justify-content: flex-end;
      display: flex;

      .el-pagination {
        white-space: nowrap;
        padding: 2px 5px 2px 0px;
        color: #303133;
        font-weight: 700;

        .el-pager li {
          background: #ccc;
          -webkit-box-sizing: border-box;
        }

        .el-pager li .active {
          background-color: #FF6820;
          color: #FFF;
        }
      }


    }

  }
}
.dialogOrder {
  width: 100%;
  margin: auto auto 20px auto;
  .dialogBody {
    width: 100%;
    line-height: 30px;
    display: flex;
    .lf {
      width: 50%;
      color: #666;
      text-align: right;
    }
    .rg {
      width: 50%;
      color: #000;
      text-align: left;
    }
  }
}
.dialogFooter {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
}
</style>
